.rdw-editor-toolbar {
  margin-bottom: 0;
}
.rdw-editor-main {
  border: 1px solid #F1F1F1;
  border-top: 0;
  min-height: 200px;
  padding: 5px 15px;
}

.border-radius-top-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.border-red {
  &.is-invalid {
    border-top: #F1416C 1px solid;
  }
}

.ql-container {
  height: 200px !important;
  &.ql-snow {
    border-color: #EFF2F5 !important;
  }
}
.ql-editor {
  border-color: #EFF2F5 !important;
}

.ql-toolbar.ql-snow {
  border-color: #EFF2F5 !important;
}