.filter-class {
  tr:hover {
    background-color: transparent !important;
  }
  input {
    color: #000;
  }

  input::placeholder {
    color: #5e6278!important;
  }

}

.react-bootstrap-table-pagination {
  border-top: 1px solid #EFF2F5;
  padding-top: 20px;

  .btn-active-primary:after {
    display: inline-block;
    margin-left: 0.555em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .dropdown-menu.show {
    display: inline-flex;
    position: absolute;
    z-index: 9999999;
    /* left: 0; */
    box-shadow: none;
  }
}