.vertical-middle {
  vertical-align: middle;
}

.opacity-30{
  opacity: 0.3;
}

.symbol-45px {
  i {
    &::before {
      font-size: 30px;
    }
  }
}

.symbol-35px {
  i {
    &::before {
      font-size: 35px;
    }
  }
}


.symbol-25px {
  i {
    &::before {
      font-size: 25px;
    }
  }
}

.symbol-30px {
  i {
    &::before {
      font-size: 30px;
    }
  }
}

.word-break-all {
  word-break: break-all;
}

.select-folder-color {
  .color-element {
    height: 26px;
    width: 26px;
    //& > span:after {
    //  border: solid #ffffff;
    //  margin-left: -4px;
    //  margin-top: -9px;
    //  width: 8px;
    //  height: 14px;
    //}
  }
  .kt-checkbox-inline {

    .kt-checkbox {
      height: 25px;
      margin: 0;

    }

  }
}

.checkbox-color_1 {

    background: #995d4b !important;


  &.kt-checkbox--solid > span {
    background: #995d4b !important;
  }
}

.checkbox-color_2 {

    background: #c05451 !important;


  &.kt-checkbox--solid > span {
    background: #c05451 !important;
  }
}

.checkbox-color_3 {

    background: #ed1e18 !important;


  &.kt-checkbox--solid > span {
    background: #ed1e18 !important;
  }
}

.checkbox-color_4 {

    background: #f03d2c !important;


  &.kt-checkbox--solid > span {
    background: #f03d2c !important;
  }
}

.checkbox-color_5 {

    background: #f75c26 !important;


  &.kt-checkbox--solid > span {
    background: #f75c26 !important;
  }
}

.checkbox-color_6 {

    background: #fa9b30 !important;


  &.kt-checkbox--solid > span {
    background: #fa9b30 !important;
  }
}

.checkbox-color_7 {

    background: #47d07d !important;


  &.kt-checkbox--solid > span {
    background: #47d07d !important;
  }
}

.checkbox-color_8 {

    background: #28994f !important;


  &.kt-checkbox--solid > span {
    background: #28994f !important;
  }
}

.checkbox-color_9 {

    background: #72ca2e !important;


  &.kt-checkbox--solid > span {
    background: #72ca2e !important;
  }
}

.checkbox-color_10 {

    background: #a9d653 !important;


  &.kt-checkbox--solid > span {
    background: #a9d653 !important;
  }
}

.checkbox-color_11 {

    background: #f9e56a !important;


  &.kt-checkbox--solid > span {
    background: #f9e56a !important;
  }
}

.checkbox-color_12 {

    background: #f6c74c !important;


  &.kt-checkbox--solid > span {
    background: #f6c74c !important;
  }
}

.checkbox-color_13 {

    background: #88dcb1 !important;


  &.kt-checkbox--solid > span {
    background: #88dcb1 !important;
  }
}

.checkbox-color_14 {

    background: #93dbe1 !important;


  &.kt-checkbox--solid > span {
    background: #93dbe1 !important;
  }
}

.checkbox-color_15 {

    background: #90bae2 !important;


  &.kt-checkbox--solid > span {
    background: #90bae2 !important;
  }
}

.checkbox-color_16 {

    background: #3b72e4 !important;


  &.kt-checkbox--solid > span {
    background: #3b72e4 !important;
  }
}

.checkbox-color_17 {

    background: #8788ff !important;


  &.kt-checkbox--solid > span {
    background: #8788ff !important;
  }
}

.checkbox-color_18 {

    background: #a785ff !important;


  &.kt-checkbox--solid > span {
    background: #a785ff !important;
  }
}

.checkbox-color_19 {

    background: #4d5055 !important;


  &.kt-checkbox--solid > span {
    background: #4d5055 !important;
  }
}

.checkbox-color_20 {

    background: #bdafb2 !important;


  &.kt-checkbox--solid > span {
    background: #bdafb2 !important;
  }
}

.checkbox-color_21 {

    background: #be949c !important;


  &.kt-checkbox--solid > span {
    background: #be949c !important;
  }
}

.checkbox-color_22 {

    background: #ed7aa4 !important;


  &.kt-checkbox--solid > span {
    background: #ed7aa4 !important;
  }
}

.checkbox-color_23 {

    background: #bc5ae3 !important;


  &.kt-checkbox--solid > span {
    background: #bc5ae3 !important;
  }
}


.checkbox-color_24 {

    background: #8f63de !important;


  &.kt-checkbox--solid > span {
    background: #8f63de !important;
  }
}

.color_1 {
  i::before {
    color: #995d4b !important;
  }
  color: #995d4b !important;
}

.bg-color_1 {
  background-color: #995d4b !important;
}

.color_2 {
  i::before {
    color: #c05451 !important;
  }
  color: #c05451 !important;
}

.bg-color_2 {
  background-color: #c05451 !important;
}

.color_3 {
  i::before {
    color: #ed1e18 !important;
  }
  color: #ed1e18 !important;
}

.bg-color_3 {
  background-color: #ed1e18 !important;
}

.color_4 {
  i::before {
    color: #f03d2c !important;
  }
  color: #f03d2c !important;
}

.bg-color_4 {
  background-color: #f03d2c !important;
}

.color_5 {
  i::before {
    color: #f75c26 !important;
  }
  color: #f75c26 !important;
}

.bg-color_5 {
  background-color: #f75c26 !important;
}

.color_6 {
  i::before {
    color: #fa9b30 !important;
  }
  color: #fa9b30 !important;
}

.bg-color_6 {
  background-color: #fa9b30 !important;
}

.color_7 {
  i::before {
    color: #47d07d !important;
  }
  color: #47d07d !important;
}

.bg-color_7 {
  background-color: #47d07d !important;
}

.color_8 {
  i::before {
    color: #28994f !important;
  }
  color: #28994f !important;
}

.bg-color_8 {
  background-color: #28994f !important;
}

.color_9 {
  i::before {
    color: #72ca2e !important;
  }
  color: #72ca2e !important;
}

.bg-color_9 {
  background-color: #72ca2e !important;
}

.color_10 {
  i::before {
    color: #a9d653 !important;
  }
  color: #a9d653 !important;
}

.bg-color_10 {
  background-color: #a9d653 !important;
}

.color_11 {
  i::before {
    color: #f9e56a !important;
  }
  color: #f9e56a !important;
}

.bg-color_11 {
  background-color: #f9e56a !important;
}

.color_12 {
  i::before {
    color: #f6c74c !important;
  }
  color: #f6c74c !important;
}

.bg-color_12 {
  background-color: #f6c74c !important;
}

.color_13 {
  i::before {
    color: #88dcb1 !important;
  }
  color: #88dcb1 !important;
}

.bg-color_13 {
  background-color: #88dcb1 !important;
}

.color_14 {
  i::before {
    color: #93dbe1 !important;
  }
  color: #93dbe1 !important;
}

.bg-color_14 {
  background-color: #93dbe1 !important;
}

.color_15 {
  i::before {
    color: #90bae2 !important;
  }
  color: #90bae2 !important;
}

.bg-color_15 {
  background-color: #90bae2 !important;
}

.color_16 {
  i::before {
    color: #3b72e4 !important;
  }
  color: #3b72e4 !important;
}

.bg-color_16 {
  background-color: #3b72e4 !important;
}

.color_17 {
  i::before {
    color: #8788ff !important;
  }
  color: #8788ff !important;
}

.bg-color_17 {
  background-color: #8788ff !important;
}

.color_18 {
  i::before {
    color: #a785ff !important;
  }
  color: #a785ff !important;
}

.bg-color_18 {
  background-color: #a785ff !important;
}

.color_19 {
  i::before {
    color: #4d5055 !important;
  }
  color: #4d5055 !important;
}

.bg-color_19 {
  background-color: #4d5055 !important;
}

.color_20 {
  i::before {
    color: #bdafb2 !important;
  }
  color: #bdafb2 !important;
}

.bg-color_20 {
  background-color: #bdafb2 !important;
}

.color_21 {
  i::before {
    color: #be949c !important;
  }
  color: #be949c !important;
}

.bg-color_21 {
  background-color: #be949c !important;
}

.color_22 {
  i::before {
    color: #ed7aa4 !important;
  }
  color: #ed7aa4 !important;
}

.bg-color_22 {
  background-color: #ed7aa4 !important;
}

.color_23 {
  i::before {
    color: #bc5ae3 !important;
  }
  color: #bc5ae3 !important;
}

.bg-color_23 {
  background-color: #bc5ae3 !important;
}

.color_24 {
  i::before {
    color: #8f63de !important;
  }
  color: #8f63de !important;
}

.bg-color_24 {
  background-color: #8f63de !important;
}

.bg-light-primary-custom {
  background: #a7e4ff !important;//rgba(85, 120, 235, 0.1);
}

.badge-color_1_dark {
  background: #4c8eaa !important;
  color: #ffffff !important;
}
.badge-color_2_dark {
  background: #a38828 !important;
  color: #ffffff !important;
}
.badge-color_3_dark {
  background: #18c6ed !important;
  color: #ffffff !important;
}
.badge-color_4_dark {
  background: #234ca9 !important;
  color: #ffffff !important;
}
.badge-color_5_dark {
  background: #a9233c !important;
  color: #ffffff !important;
}
.badge-color_6_dark {
  background: #f7b461 !important;
  color: #ffffff !important;
}
.badge-color_7_dark {
  background: #b15a39 !important;
  color: #ffffff !important;
}
.badge-color_8_dark {
  background: #a76ade !important;
  color: #ffffff !important;
}
.badge-color_9_dark {
  background: #ff6060 !important;
  color: #ffffff !important;
}
.badge-color_10_dark {
  background: #bfc14b !important;
  color: #ffffff !important;
}
.badge-color_11_dark {
  background: #48af42 !important;
  color: #ffffff !important;
}
.badge-color_12_dark {
  background: #ff8d00 !important;
  color: #ffffff !important;
}
.badge-color_13_dark {
  background: #989898 !important;
  color: #ffffff !important;
}

.badge-blue {
  background: #009ef7 !important;
  color: #ffffff !important;
}

.badge-gray {
  background: #8e95ab !important;
  color: #ffffff !important;
}

.MuiPaper-rounded {
  box-shadow: none !important;
}

.table-export {
  padding: 8px 8px 8px 12px !important;
  border-radius: 100% !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.sex-circle-male {
  //background: #000;
  //color: #000;
}

.sex-circle-female {
  background: #fff09b !important;
  color: #e89800 !important;
}

.sex-circle-others {
  background: #e5c8ff !important;
  color: #9c4ae7 !important;
}

.sex-circle-company {
  background: #c3eebd !important;
  color: #41bf30 !important;
}


.phone-input {
  //border: 1px solid #E4E6EF !important;
}

.input-height {
  height: 44px !important;
}

.input-width {
  width: 216px !important;
}

.switch-area {
  .form-check-label {
    color: #181C32 !important;
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
    font-weight: 600 !important;
    font-size: 1.075rem !important;
  }
}

.MuiStepLabel-iconContainer {
  &.Mui-active {
    circle {
      color: #009EF7 !important;
    }
  }
}

.extra-large-size {
  font-size: 62px !important;
}

.fs-275 {
  font-size: 2.75rem !important;
}

.fs-175 {
  font-size: 1.75rem !important;
}

.fs-125 {
  font-size: 1.25rem !important;
}

.max-w-300px {
    max-width: 300px !important;
}

.document-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 260px !important;
}

.document-name-detail {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 320px !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.user-top-menu {
  .menu-title {
    color: #7E8299 !important;
  }
}

.w-70 {
    width: 70% !important;
}

.w-30 {
    width: 30% !important;
}

.w-min-120px{
    min-width: 120px !important;
}


.svj-avatar {
  position: relative;
  .dropdown-toggle {
    &::after {
      display: none !important;
    }
  }
  .image-background {
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  .renderUploadImage {
    position: absolute;
    right: 12px;
    top: 8px;

    .icon-upload {
      position: absolute;
      right: 0;
      opacity: 0.7;
      display: flex;
      text-align: center;
      justify-content: center;
      background: #fff;
      width: 41px;
      height: 33px;
      padding-top: 4px;
    }
  }
}

#surveyDetail {
  .accordion-button:not(.collapsed) {
    background: #fff;
  }
}

.main-table tbody tr:hover {
  //color: #212529;
  background-color: #fafbfc !important;
}

.main-table .filter-class tr:hover {
  //color: #212529;
  background-color: transparent !important;
}

.main-table {
  tbody {
    tr {
      td:first-child {
        padding-left: 1.25rem;
      }
    }
  }
}

.min-width-140px {
  min-width: 140px !important;
}

.width-140px {
  width: 140px !important;
}

.z-index-99 {
    z-index: 99 !important;
}

.z-index-98 {
  z-index: 98 !important;
}


@media print {
  .top-menu,
  .page-buttons-area,
  .button {
    display: none !important;
  }
}

.default-background {
    background: #F5F8FA !important;
}

.react-tel-input {
  .selected-flag:hover, .selected-flag:focus, .selected-flag.open {
    background: #f5f8fa !important;
  }

  .phone-input {
    font-size: 1.15rem !important;
    font-weight: normal !important;
    font-family: "Roboto", Helvetica, "sans-serif" !important;
  }
}

.fv-plugins-message-container.invalid-feedback, .fv-plugins-message-container.valid-feedback {
  display: block;
  font-weight: 400;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .form-control:invalid, .form-control.is-invalid,  {
  background: #F5F8FA !important;
  padding-right: 0 !important;
}


.sticky-table-thead thead th {
  position: sticky;
  top: 120px;
  background-color: white;
  z-index: 97;
}

.w-px-120 {
    width: 120px !important;
}

.fc-h-event.isPrivate {
  .fc-event-main {
    margin-left: 20px !important;
  }
}

.custom-border-card {
  background: #F5F8FA;
  border: #EFF2F5 1px solid;
  padding: 1rem;
  border-radius: 0.475rem;
}

.fw-bold-custom {
  font-weight: 600 !important;
}

.room_owners {
  .fs-6 {
    .d-flex.align-items-center > *:not(:first-child) {
      margin-bottom: 15px;
      margin-right: 15px;
    }
  }
}

.timeline-icon i.fa {
  color: #afb6cf;
}

.gallery-area {
  .description {
    .short {
      line-height: 1.5em;
      height: 1.5em;
      overflow: hidden;
      /*white-space: nowrap;*/
      text-overflow: ellipsis;
    }
  }

  .gallery {
    .image-area {
      .image-wrapper {
        justify-content: center;
        img {
          height: 170px;
        }
      }
    }
  }
}

.custom-stepper {
  .MuiStepLabel-iconContainer {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 1.75rem !important;
    min-width: 1.75rem !important;
    padding: 0 0.1rem !important;
    line-height: 0 !important;
    color: var(--bs-primary-inverse) !important;
    background-color: var(--bs-primary) !important;
    font-size: 1.5rem !important;
    @include border-radius($border-radius !important);
  }
  .MuiStepLabel-alternativeLabel {
    text-align: left !important;
    align-items: start !important;
  }

  .MuiStepIcon-text {
    font-size: 1.2rem !important;
  }

  .MuiStepLabel-label {
    margin-left: 10px !important;
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }

  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0) !important;
  }
  .MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed {
    width: 0.7em !important;
    color: #fff !important;

    svg {
      fill: #fff !important;
    }

  }

}

.symbol-label-45px {
  width: 45px !important;
  height: 45px !important;
}

.menu-badge {
  .badge-count-custom {
    color: #a5b1ff;
    background-color: #454a6c;
    font-size: .70rem;
  }
}

.aside-dark .hover-scroll-overlay-y {
  --scrollbar-space: 0.0rem !important;
  --scrollbar-width: 0.5rem !important;
}

.form-select {
  [class$="-control"] {
    height: auto !important;
    min-height: 45.88px !important;
  }
}

.min-h-135px {
    min-height: 135px !important;
}

.border-left-10 {
  border-left-width: 10px!important
}

.d-md-none {
    display: none !important;
}

.search-items .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a5b1fe;
}

@media print {
  .btn {
    display: block !important;
  }
  .btn-sm {
    display: block !important;
  }
}

@media print {
  .tableUsers {
    .vote-danger {
      border-radius: 0.475rem !important;
      color: #fff !important;
      background-color: #F1416C !important;
      border-color: #F1416C !important;
      -webkit-print-color-adjust: exact !important;
    }
    .vote-success {
      border-radius: 0.475rem !important;
      color: #fff !important;
      background-color: #50CD89 !important;
      border-color: #50CD89 !important;
      -webkit-print-color-adjust: exact !important;
    }
    .vote-warning {
      border-radius: 0.475rem !important;
      color: #fff;
      background-color: #FFC700;
      border-color: #FFC700;
      -webkit-print-color-adjust: exact;
    }
    .vote-primary {
      border-radius: 0.475rem !important;
      color: #fff;
      background-color: #009EF7;
      border-color: #009EF7;
      -webkit-print-color-adjust: exact;
    }
  }
}

.free-area-wrapper {
  position: sticky;
  background: #f8f9fa;
  width: 100%;
  z-index: 10!important;
}

.symbol-label-40px {
  width: 40px !important;
  height: 40px !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: attr(data-translation);
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: attr(data-translation);
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: attr(data-translation);
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: attr(data-translation);
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: attr(data-translation);
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: attr(data-translation);
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value=""]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value=""]::before {
  content: attr(data-translation);
}

.quill {
  .ql-tooltip.ql-editing {
    z-index: 999;
  }
}

.selection-cell {
  padding-left: 0 !important
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: 'Uložit' !important;
}

.ql-snow .ql-tooltip[data-mode=link]::before {
  content: "Vložte odkaz:" !important;
}


//.react-bootstrap-table {
//  .selection-cell input[type=checkbox]{
//    border-radius: 0.45em;
//    //height: 1.1rem;
//    //width: 1.1rem;
//    background: #000 !important;
//  }
//
//  .selection-cell input[type=checkbox]:checked {
//    background-color: #009EF7 !important;
//  }
//}