
.badge-important {
  color: #fff;
  background: #fd397a;
  min-width: 130px;
}

.badge-general {
  color: #fff;
  background: #0abb87;
}

.badge-internal {
  color: #fff;
  background: #ffb822;
}

.badge-disorder {
  background: #777DA7 !important;
  color: #fff;
}

.badge-repair {
  color: #fff;
  background: #5d78ff;
}

.badge-maintenance {
  background: #a7a3a3 !important;
  color: #fff;
}

.badge-complain {
  color: #fff;
  background: #282a3c;
}
