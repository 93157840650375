
.gallery-modal {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2000;

  .gallery-figcaption--info {
    display: none !important;
  }

  .gallery {
    &:hover {
      background-color: rgba(0, 0, 0, 1);
    }
  }
}

.gallery-modal .gallery-modal--overlay {
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.gallery-modal--preload {
  display: none;
}

.gallery-modal--container {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 1;
}

.gallery-modal--table {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}

.gallery-modal--cell {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.gallery-modal--content {
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  height: 100%;
  max-width: 100%;
}

.gallery-content {
  display: table;
  width: 100%;
  height: 100%;
}

.gallery-top {
  display: table-row;
}

.gallery-top--inner {
  padding: 60px 15px 20px;
}

.gallery {
  //display: table;
  font-size: 1rem;
  width: 100%;
  padding-bottom: 10px;
}

.gallery-modal .gallery {
  display: table-row;
  height: 100%;
  padding-bottom: 0;
}

.gallery .gallery-main {
  position: relative;
  z-index: 9;
}

.gallery .gallery-photos {
  width: 100%;
  max-width: 105vh;
  margin: 0px auto;
}

.gallery .gallery-photo {
  position: relative;
  width: 100%;
  height: 0px;
  padding-bottom: 75%;
}

.gallery .gallery-photos .gallery-empty {
  color: #ffffff;
  font-size: 1.45rem;
  display: block;
  text-align: center;
  padding: 25% 0;
}

.gallery .gallery-photo--current {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.gallery ul.gallery-images--ul {
  position: relative;
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.gallery ul.gallery-images--ul li.gallery-media-photo {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  z-index: 2;
}

.gallery ul.gallery-images--ul li.gallery-media-photo,
.gallery ul.gallery-images--ul li.gallery-media-photo div.picture {
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery ul.gallery-images--ul li.gallery-media-photo button.photo-button {
  display: block;
  background: none;
  border-width: 0;
  cursor: pointer;
  padding: 0 !important;
  -webkit-appearance: button;
}

.gallery ul.gallery-images--ul li.gallery-media-photo button.photo-button:focus,
.gallery ul.gallery-images--ul li.gallery-media-photo button.photo-button:active {
  outline: none;
  border-width: 0;
}

.gallery ul.gallery-images--ul li.gallery-media-photo div.picture img.photo {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  z-index: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gallery .gallery-control {
  cursor: pointer;
  position: absolute;
  top: 0px;
  height: 100%;
  z-index: 3;
  font-size: 0.5em;
  width: 40px;
  background: none;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}

.gallery .gallery-control--prev {
  left: 0;
  border: none !important;
}

.gallery .gallery-control--next {
  right: 0;
  border: none !important;
}

.gallery-figcaption {
  background-image: none;
  background-color: transparent;
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  text-align: center;
  color: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 0;
}

.mode-light .gallery-figcaption {
  color: #111111;
}

.gallery-figcaption--content {
  max-width: 105vh;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.gallery-figcaption--inner {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transform: translateY(0);
  transition: -ms-transform 0.2s ease-out 0s, -webkit-transform 0.2s ease-out 0s, transform 0.2s ease-out 0s;
}

.hide .gallery-figcaption--inner {
  transform: translateY(70px);
}

.gallery-figcaption--info {
  display: table;
  width: 100%;
}

.gallery-figcaption--info .caption-left {
  text-align: left;
  vertical-align: middle;
  display: table-cell;
}

.gallery-figcaption--info .caption-right {
  text-align: right;
  vertical-align: middle;
  display: table-cell;
}

.gallery-figcaption--info .photo-caption,
.gallery-figcaption--info .photo-subcaption {
  margin: 0;
  line-height: 1.31;
}

.gallery-figcaption--info .photo-caption {
  font-weight: 400;
  font-size: 1rem;
}

.gallery-figcaption--info .photo-subcaption {
  color: rgba(255, 255, 255, 0.65);
  font-size: 0.851rem;
}

.mode-light .gallery-figcaption--info .photo-subcaption {
  color: rgba(0, 0, 0, 0.65);
}

.gallery-figcaption--thumbnails {
  position: relative;
  height: 67px;
  overflow: hidden;
}

.gallery-figcaption--thumbnails .caption-thumbnails {
  width: 100% !important;
  position: absolute;
}

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  left: 0;
  -webkit-transition: margin 0.3s ease-out 0s;
  transition: margin 0.3s ease-out 0s;
}

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list::before,
.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list::after {
  content: "";
  display: table;
}

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list::after {
  clear: both;
}

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li {
  float: left;
  background-color: rgb(0, 0, 0);
}

.mode-light .gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li {
  background-color: rgb(255, 255, 255);
}

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li + li {
  margin-left: 10px;
}

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button {
  cursor: pointer;
  position: relative;
  display: block;
  vertical-align: bottom;
  overflow: hidden;
  background-color: #bbb;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button div.loading-spinner {
  position: absolute;
  z-index: 1001;
  width: 2em;
  height: 2em;
  top: 50%;
  left: 50%;
  margin: -1em 0 0 -1em;
  border-top-width: 0.15em;
  border-right-width: 0.15em;
  border-bottom-width: 0.15em;
  border-left-width: 0.15em;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button.active {
  cursor: default;
}

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li img.thumbnail {
  width: 100px;
  height: 67px;
  display: block;
  border-width: 0;
  position: relative;
  z-index: 1;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.1s ease opacity;
  transition: 0.1s ease opacity;
}

.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button.active img,
.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button:hover img {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
}

.gallery-figcaption .gallery-thumbnails--toggle {
  cursor: pointer;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  display: inline-block;
  color: inherit;
  font-weight: inherit;
  position: relative;
}

.gallery-figcaption .gallery-thumbnails--toggle::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.181rem;
}

.gallery-figcaption .gallery-thumbnails--toggle.hide::after {
  border-top: 6px solid #ffffff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.mode-light .gallery-figcaption .gallery-thumbnails--toggle.hide::after {
  border-top-color: #111111;
}

.gallery-figcaption .gallery-thumbnails--toggle.open::after {
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.mode-light .gallery-figcaption .gallery-thumbnails--toggle.open::after {
  border-bottom-color: #111111;
}

.gallery-modal--close {
  position: absolute;
  z-index: 10;
  right: 0px;
  top: 0px;
  padding: 15px;
}

.gallery-modal--close button.gallery-close {
  cursor: pointer;
  background: none transparent;
  color: 'buttontext';
  border: 0;
  display: block;
  padding: 0.5rem;
}

div.loading-spinner,
div.loading-spinner::after,
.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button::before,
.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button::after {
  border-radius: 50%;
}

div.loading-spinner,
div.loading-spinner::after {
  width: 6em;
  height: 6em;
}

div.loading-spinner,
.gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button::after {
  font-size: 10px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: GalleryLoadingSpinner 1.1s infinite linear;
  animation: GalleryLoadingSpinner 1.1s infinite linear;
}

div.loading-spinner {
  margin: 60px auto;
  position: relative;
  border-top: 0.35em solid rgba(255, 255, 255, 0.2);
  border-right: 0.35em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.35em solid rgba(255, 255, 255, 0.2);
  border-left: 0.35em solid #ffffff;
}

.mode-light div.loading-spinner {
  border-top-color: rgba(0, 0, 0, 0.1);
  border-right-color: rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-left-color: #111111;
}

@-webkit-keyframes GalleryLoadingSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes GalleryLoadingSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.gallery ul.gallery-images--ul li.gallery-media-photo.loading::before {
  z-index: 999;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
}

.mode-light .gallery ul.gallery-images--ul li.gallery-media-photo.loading::before {
  background-color: rgba(255, 255, 255, 1);
}

.gallery ul.gallery-images--ul li.gallery-media-photo.loading div.loading-spinner,
.gallery ul.gallery-images--ul li.gallery-media-photo div.picture.loading div.loading-spinner {
  position: absolute;
  z-index: 1001;
  top: 50%;
  left: 50%;
  margin: -1rem 0 0 -1rem; /* visually centered */
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* gallery animation */
.gallery__animation-appear {
  opacity: 0.01;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}

.gallery__animation-appear-active {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transition: opacity 150ms ease-out;
  -o-transition: opacity 150ms ease-out;
  -moz-transition: opacity 150ms ease-out;
  transition: opacity 150ms ease-out;
}

.gallery__image-transition--backwards-enter {
  opacity: 0.01;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  -webkit-transform: translateX(-5px);
  -moz-transform: translateX(-5px);
  -ms-transform: translateX(-5px);
  -o-transform: translateX(-5px);
  transform: translateX(-5px)
}

.gallery__image-transition--forwards-enter {
  opacity: 0.01;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
  transform: translateX(5px)
}

.gallery__image-transition--backwards-enter-active,
.gallery__image-transition--forwards-enter-active {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 150ms ease-out, -webkit-transform 150ms ease-out;
  transition: opacity 150ms ease-out, -webkit-transform 150ms ease-out;
  -o-transition: opacity 150ms ease-out, -o-transform 150ms ease-out;
  -moz-transition: opacity 150ms ease-out, transform 150ms ease-out, -moz-transform 150ms ease-out;
  transition: opacity 150ms ease-out, transform 150ms ease-out;
  transition: opacity 150ms ease-out, transform 150ms ease-out, -webkit-transform 150ms ease-out, -moz-transform 150ms ease-out, -o-transform 150ms ease-out
}

.gallery__image-transition--backwards-leave,
.gallery__image-transition--forwards-leave {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0)
}

.gallery__image-transition--backwards-leave-active,
.gallery__image-transition--forwards-leave-active {
  opacity: 0.01;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  -webkit-transition: opacity 150ms ease-out;
  -o-transition: opacity 150ms ease-out;
  -moz-transition: opacity 150ms ease-out;
  transition: opacity 150ms ease-out
}

.right-0 {
  right: 0;
}
.image-area {
  .remove-area {
    opacity: 0;
  }

  &:hover {
    //background: #eff0f4;
    //border-radius: 4px;

    .folder-icon {
      i {
        color: #dadeeb;
      }
    }

    .remove-area {
      opacity: 1;
    }
  }
}

@media (max-width: 743px) {
  .gallery-figcaption--info {
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gallery-figcaption .gallery-thumbnails--toggle {
    display: none;
  }
}

@media (min-width: 744px) {
  .gallery .gallery-photo {
    padding-bottom: 67%;
  }

  .gallery-figcaption--info {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media (min-width: 1128px) {
  .gallery .gallery-control {
    width: 75px;
    font-size: medium;
  }
}




